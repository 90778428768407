import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logoImg from '../../Images/letters1.png'
import './footer.css'

const Footer1 = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={6} md={6} className="footer-info">
              <h3>
                <img src={logoImg} alt="" title="" />
              </h3>
              <p>
                  En vapo.bo, ofrecemos productos de la más alta calidad para satisfacer a nuestros clientes. Nos dedicamos a brindar una experiencia de compra excepcional, con una amplia variedad de productos innovadores que marcan la diferencia en vapers.
              </p>

            </Col>
{/* 
            <Col lg={4} md={6} className="footer-links">
              <h4>Links útiles</h4>
              <ul>
                <li><i className="ion-ios-arrow-right"></i> <a href="#">Inicio</a></li>
                <li><i className="ion-ios-arrow-right"></i> <a href="#about">Info</a></li>
                <li><i className="ion-ios-arrow-right"></i> <a href="#services">Servicios</a></li>
                <li><i className="ion-ios-arrow-right"></i> <a href="#clients">Clientes</a></li>
                <li><i className="ion-ios-arrow-right"></i> <a href="/portafolios/">Portafolios</a></li>
              </ul>
            </Col> */}

            <Col lg={6} md={6} className="footer-contact">
              <h4>Contáctanos</h4>
              {/* <p>
                Safa <br />
                <strong>Email: </strong>
                <a href="mailto:SafaWebDev@gmail.com" className="footer-email">SafaWebDev@gmail.com</a>
              </p> */}

              <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=61561754225529&locale=es_LA" target="_blank" rel="noopener noreferrer" className="facebook">
                  <ion-icon className="smaller" name="logo-facebook"></ion-icon>
                </a>
                <a href="https://wa.me/59172767792" target="_blank" rel="noopener noreferrer" className="facebook">
                  <ion-icon className="smaller" name="logo-whatsapp"></ion-icon>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}

export default Footer1;
