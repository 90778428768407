import React from "react";

const WhatsAppButton = () => {
  const buttonStyle = {
    position: "fixed",
    bottom: 36,
    right: 12,
    backgroundColor: "#25D366",
    color: "#fff",
    borderRadius: "50%",
    border: "none",
    padding: "12px",
    cursor: "pointer",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    zIndex: 10000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const hoverStyle = {
    backgroundColor: "#21b056",
  };

  const iconSize = 32;

  const message = encodeURIComponent("¡Hola! Quisiera hacer un pedido...");

  return (
    <a
      href={`https://wa.me/59172767792?text=${message}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        style={buttonStyle}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor)}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)}
      >
        <ion-icon name="logo-whatsapp" style={{ fontSize: iconSize, color: "white" }}></ion-icon>
      </button>
    </a>
  );
};

export default WhatsAppButton;
